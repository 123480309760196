<template>
	<div class="video-player">
		<ion-skeleton-text
			animated
			style="height: 350px"
			v-if="loading && !cannotLoadBunnyEmbed"
		/>
		<div
			:class="[
				'video-player__video',
				loading || !isConnectedToInternet
					? 'video-player__video--hidden'
					: '',
			]"
			v-if="playerType === 'vimeo'"
			:key="reloadVideoElementCount"
		>
			<iframe
				ref="vimeoElement"
				:src="`${videoSource}`"
				allowfullscreen
			></iframe>
		</div>

		<video
			v-if="playerType === 'default'"
			ref="videoElement"
			height="350"
			controls
			:class="[
				loading || !isConnectedToInternet
					? 'video-player__video--hidden'
					: '',
			]"
			:key="reloadVideoElementCount"
		>
			<source :src="videoSource" />
		</video>

		<div
			v-if="
				playerType === 'bunny' &&
				(!cannotLoadBunnyEmbed || useCustomBunnyPlayer) &&
				bunnyVideoSource
			"
			:class="[
				'video-player__video',
				loading || !isConnectedToInternet
					? 'video-player__video--hidden'
					: '',
			]"
			:key="reloadVideoElementCount"
		>
			<VideoPlayerVideoJS
				v-if="useCustomBunnyPlayer"
				@ready="onBunnyCustomPlayerReady($event)"
				:options="{
					autoplay: false,
					controls: true,
					preload: 'metadata',
					controlBar: {
						pictureInPictureToggle: false
					},
					fluid: true,
					muted: false,
					loop: false,
					playsinline: true,
					posterImage: true,
					sources: [
						{
							src: bunnyVideoSource,
							type: 'application/x-mpegURL',
						},
					],
				}"
			></VideoPlayerVideoJS>
			<iframe
				v-if="!cannotLoadBunnyEmbed && !useCustomBunnyPlayer"
				id="bunny-player"
				:src="`${videoSource}?autoplay=false&loop=false&muted=false&preload=true&responsive=true&showSpeed=false`"
				loading="lazy"
				allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
				allowfullscreen="true"
			></iframe>
		</div>
		<div
			class="video-player--errored"
			v-if="
				(!loading && errored) ||
				!isConnectedToInternet ||
				(cannotLoadBunnyEmbed && !useCustomBunnyPlayer)
			"
		>
			<template v-if="!isConnectedToInternet">
				<MaterialDesignIcon
					class="wifi-alert-icon"
					icon="wifi-alert"
					size="large"
				/>
				<p class="no-items-text">
					No internet connection found.<br />Please check your
					internet connection and try again.
				</p>
			</template>
			<template v-else-if="cannotLoadBunnyEmbed && playerType === 'bunny' && !useCustomBunnyPlayer">
				<MaterialDesignIcon icon="alert" size="large" v-if="errored" />
				<p class="no-items-text">
					The video player is currently unavailable on this app.
					Please go to {{ mobileURL }} to continue learning.
				</p>
				<ion-button @click="goToMobileURL()">GO TO BROWSER</ion-button>
			</template>
			<template v-else>
				<MaterialDesignIcon icon="alert" size="large" v-if="errored" />
				<p class="no-items-text">Unable to Load Video</p>
			</template>
			<ion-button
				class="ion-margin-top"
				@click="reloadVideoElements()"
				color="primary"
				:loading="true"
				v-if="isConnectedToInternet && (!cannotLoadBunnyEmbed || playerType !== 'bunny')"
			>
				Reload
				<MaterialDesignIcon
					color="light"
					icon="reload"
					size="small"
					style="margin-left: 10px"
				/>
			</ion-button>
		</div>
		<div
			class="additional-help"
			v-if="!loading && errored && isConnectedToInternet"
		>
			<p>
				Still having issues?
				<span @click="showHelpModal()">Contact Us</span>
			</p>
		</div>
	</div>
</template>

<script>
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonLoading,
  IonRange,
  IonSkeletonText,
  IonToolbar
} from '@ionic/vue';
import Player from '@vimeo/player';
import { useStore } from "vuex";
import { Preferences } from '@capacitor/preferences';
import { useRouter } from "vue-router";
import MaterialDesignIcon from "../components/MaterialDesignIcon.vue";
import playerjs from 'player.js';
import { api } from "@/core/api/api";
import envConfig from "@/core/config/env.config";
import VideoPlayerVideoJS from "./video/VideoPlayerVideoJS.vue";

export default defineComponent({
	components: {
		IonButton,
		IonButtons,
		IonIcon,
		IonLoading,
		IonRange,
		IonSkeletonText,
		IonToolbar,
		MaterialDesignIcon,
		VideoPlayerVideoJS
	},
	name: "VideoPlayer",
	props: {
		allowVideoPlay: {
			default: true,
			type: Boolean
		},
		videoPreviouslyComplete: {
			type: Boolean
		},
		videoSource: {
			type: String,
			required: true
		},
		isCompleted: {
			type: Boolean,
			default: false
		},
		resourceID: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			errored: false,
			videoFinished: false,
			loading: true,
			reloadVideoElementCount: 0,
			mobileURL: envConfig.mobileURL
		}
	},
	computed: {
		isConnectedToInternet() {
			return this.store?.getters?.connectionStatus?.connected;
		},
		bunnyPullZone() {
			return this.store?.getters?.institutionSettings?.videoSettings?.bunny?.pullZone || '';
		},
		useCustomBunnyPlayer() {
			return this.store?.getters?.institutionSettings?.videoSettings?.bunny?.useCustomPlayer || false;
		},
		bunnyVideoSource() {
			if (!this.bunnyPullZone || !this.videoSource) { return ''; }

			const videoSourceURL = new URL(this.videoSource);
			const videoID = videoSourceURL.pathname.split('/').pop();
			if (!videoID) { return ''; }

			return `https://${this.bunnyPullZone}/${videoID}/playlist.m3u8`;
		},
		cannotLoadBunnyEmbed() {
			return !this.store?.getters?.institutionSettings?.videoSettings?.bunny?.embedPlayerEnabledOnIOS && this.store?.getters?.appPlatform === 'ios' && this.playerType === 'bunny';
		},
		isDone() {
			return this.videoFinished || this.videoPreviouslyComplete;
		},
		overlayMenuActive() {
			return this.store?.state?.showHelpModal || this.store?.state?.showSettingsModal || !this.allowVideoPlay
		},
		playerType() {
			if (!this.videoSource) { return false; }

			if (this.videoSource.includes('vimeo.com')) {
				return 'vimeo';
			} else if (this.videoSource.includes('iframe.mediadelivery.net')) {
				return 'bunny';
			} else if (['.mp4', '.webm'].some((ext) => this.videoSource.endsWith(ext))) {
				return 'default';
			}
		}
	},
	methods: {
		async videoEnded() {
			this.videoFinished = true;
			this.$emit('videoComplete');
		},
		goToMobileURL() {
			window.open(this.mobileURL, '_blank');
		},
		emitError(error) {
			const message = error instanceof Error
				? error.message
				: typeof error === 'string'
				? error
				: 'Unknown Error';
			this.$emit('error', message);
		},
		showHelpModal() {
			this.store.commit('showHelpModal', '');
		},
		logActivity(event = '', status = '', details = '') {
			api.logMobileActivity({
				institution: this.$store.getters.institutionSettings.slug,
				event: event,
				affectedUserID: this.$store.getters.user.userID,
				additionalData:{
					email: this.$store.getters.user.email,
					activeUser: this.$store.getters.selectedUser,
					currentAppVersions: this.$store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Video Player'],
					status: status,
					details: details
				}
			});
		},
		reloadVideoElements() {
			// Turn on the loading animation.
			this.loading = true;

			// Log the reload.
			this.logActivity('Video Player Reload', 'Success', 'Reloading ' + this.uppercaseFirstLetter(this.playerType));

			// Increment to force a rebuild of any related video component.
			this.reloadVideoElementCount += 1;

			// Delay setting up the video again so the components can rebuild.
			setTimeout(() => this.setupVideo(), 3000);
		},
		onBunnyCustomPlayerReady(event) {
			this.player = event;
			
			this.player.on('loadedmetadata', () => {
				this.setupBunnyCustomPlayerListeners();
			})
		},
		async setupBunnyCustomPlayerListeners() {
			const videoLength = this.player.duration();

			this.player.on('error', (e) => {
            	this.emitError(e);
				this.errored = true;
			});

			this.loading = false;

			if (this.isCompleted) return;

			const storageKey = `video-progress-${this.resourceID}`;

			let storedTime = await Preferences.get({ key: storageKey });
			let timeWatched = storedTime.value ? parseFloat(storedTime.value) : 0;

			// Make sure that the timeWatched is never larger than the video length
			if (timeWatched > videoLength) {
				timeWatched = videoLength - 5;
			}

			if (timeWatched) this.player.currentTime(timeWatched);

			this.player.on('timeupdate', () => {
				const seconds = this.player.currentTime();
				if (seconds > timeWatched) {
					if (seconds - 1 < timeWatched) {
						// Moved less than a second, is good progress
						timeWatched = seconds;
						Preferences.set({ key: storageKey, value: timeWatched.toString() })
					} else {
						// Moved more than a second, send them back
						this.player.currentTime(timeWatched);
					}
				}
			});

			this.player.on('ended', () => {
				// Only accept the ended event if the user actually watched the whole thing
				if (timeWatched + 5 > videoLength) {
					this.videoEnded()
				}
			})
		},
		async setupVideo() {

			// Set our error and loading state.
			this.errored = false;
			this.loading = true;

			try {
				if(this.playerType === 'bunny' && !this.cannotLoadBunnyEmbed && !this.useCustomBunnyPlayer) {
					const player = new playerjs.Player('bunny-player');

					player.on('error', (e) => {
						this.emitError(e);
						this.errored = true;
					});

					// Wait until the player is actually ready
					// Sometimes the player will be "ready" before the video data has fully loaded.
					// So, wait until the video's duration has loaded, using exponential backoff
					const delay = (ms) => new Promise((res) => setTimeout(res, ms));

					const getLength = async (retries = 0) => {
						if (retries > 5) throw new Error("Video player failed to load");

						const length = await new Promise((res) => player.getDuration((val) => res(val)));
						if (length > 0) return length;

						await delay(10 ** retries)
						return getLength(retries + 1);
					}

					const videoLength = await new Promise((res) => player.on('ready', () => res())).then(() => getLength());

					this.loading = false;

					if (this.isCompleted) return; // Don't setup any time restrictions if the video is already complete


					const storageKey = `video-progress-${this.resourceID}`;

					let storedTime = await Preferences.get({ key: storageKey });
					let timeWatched = storedTime.value ? parseFloat(storedTime.value) : 0;

					// Make sure that the timeWatched is never larger than the video length
					if (timeWatched > videoLength) {
						timeWatched = videoLength - 5;
					}

					if (timeWatched) player.setCurrentTime(timeWatched);

					player.on("timeupdate", function({seconds}) {
						if (seconds > timeWatched) {
							if (seconds - 1 < timeWatched) {
								// Moved less than a second, is good progress
								timeWatched = seconds;
								Preferences.set({ key: storageKey, value: timeWatched.toString() })
							} else {
								// Moved more than a second, send them back
								player.setCurrentTime(timeWatched)
							}
						}
					});

					player.on('ended', () => {
						// Only accept the ended event if the user actually watched the whole thing
						if (timeWatched + 5 > videoLength) {
							this.videoEnded()
						}
					})
				} else if (this.playerType === 'vimeo') {
					const iFrameVimeo = this.$refs.vimeoElement;
					const player = new Player(iFrameVimeo);

					player.ready().catch((error) => {
						this.emitError(error);
						this.errored = true;
					}).finally(() => {
						this.loading = false;
					});

					player.on('ended', () => this.videoEnded())

					this.player = player;

					if (this.isCompleted) return; // Don't setup any time restrictions if the video is already complete

					const storageKey = `video-progress-${this.resourceID}`;

					let storedTime = await Preferences.get({ key: storageKey });
					let videoLength = await this.player.getDuration();
					let timeWatched = storedTime.value ? parseFloat(storedTime.value) : 0;

					// Make sure that the timeWatched is never larger than the video length set it to 10 seconds from the end of the video
					if (timeWatched > videoLength) {
						timeWatched = videoLength - 10;
					}

					if (timeWatched) await player.setCurrentTime(timeWatched);

					// From https://github.com/vimeo/player.js/issues/61
					player.on("timeupdate", function(data) {
						if (data.seconds - 1 < timeWatched && data.seconds > timeWatched) {
							timeWatched = data.seconds;
							Preferences.set({ key: storageKey, value: timeWatched.toString() })
						}
					});

					player.on("seeking", function(data) {
						if (timeWatched < data.seconds) {
							player.setCurrentTime(timeWatched);
						}

					});

					player.on("seeked", function(data) {
						if (timeWatched < data.seconds) {
							player.setCurrentTime(timeWatched);
						}
					});

					player.on("play", function(data) {
						if (timeWatched < data.seconds) {
							player.setCurrentTime(timeWatched);
						}
					});

					player.on("pause", function(data) {
						if (timeWatched < data.seconds) {
							player.setCurrentTime(timeWatched);
						}
					});

					player.on("error", function(e) {
						console.error(e)
						this.emitError(e);
					});
				} else if (this.playerType === 'default') {
					const videoElement = this.$refs.videoElement

					videoElement.addEventListener("canplay", (event) => {
						this.loading = false;
					});

					videoElement.addEventListener("error", (event) => {
						console.error(event)
						this.errored = true;
						this.loading = false;
						this.emitError(event);
					});

					videoElement.addEventListener("ended", (event) => {
						this.videoEnded()
					});

					this.player = videoElement;

					if (this.isCompleted) return; // Don't setup any time restrictions if the video is already complete

					const storageKey = `video-progress-${this.resourceID}`;

					let storedTime = await Preferences.get({ key: storageKey });
					let timeWatched = storedTime.value ? parseFloat(storedTime.value) : 0;

					if (timeWatched) videoElement.currentTime = timeWatched;

					videoElement.addEventListener("timeupdate", (event) => {
						// In dev mode, the video will change to completed after a brief moment.  Need to check here
						if (this.isCompleted) return;

						if (!this.seeking) {
							if (videoElement.currentTime - 0.3 < timeWatched && videoElement.currentTime > timeWatched) {
								timeWatched = videoElement.currentTime;
								Preferences.set({ key: storageKey, value: timeWatched.toString() });
							}
						}
					});

					videoElement.addEventListener("seeking", (event) => {
						if (this.isCompleted) return;

						this.seeking = true;

						if (videoElement.currentTime - timeWatched > 0.01) {
							videoElement.currentTime = timeWatched;
						}
					});

					videoElement.addEventListener("seeked", (event) => {
						if (this.isCompleted) return;

						this.seeking = false;
					});
				}
			} catch (error) {
				console.error(error);
				this.loading = false;
				this.emitError(error);
			}
		},
		uppercaseFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const video = ref('video');

		return {
			router,
			store,
			video
		}
	},
	watch: {
		cannotLoadBunnyEmbed(cannotLoad) {
			if (cannotLoad) {
				this.loading=false;
			} else {
				this.setupVideo();
			}
		},
		isConnectedToInternet(connected) {
			if (connected) {
				this.setupVideo();
			}
		},
		overlayMenuActive(newValue) {
			if (newValue) {
				// Pause Video
				if (this.playerType === 'vimeo') {
					this.player.pause();
				}
			}
		}
	},
	mounted() {
		this.setupVideo();
	}
});
</script>

<style lang="scss" scoped>
.no-items-icon {
	height: 35px;
	width: 35px;
	display: flex;
	justify-content: center;
}

.no-items-text {
	font-size: 0.9em;
	font-weight: 700;
	margin-top: 15px;
}
</style>

<style lang="scss">
.video-player {
	max-width: 100%;
	width: 100%;

	&__video {
		iframe {
			border: none;
			display: block;
			height: 350px;
			margin: 0 auto;
			max-width: 100% !important;
			width: 100% !important;

			@media (max-width: 590px) {
				height: 240px;
			}
		}

		&--hidden {
			height: 0;
			overflow: hidden;
		}
	}

	&--errored {
		align-items: center;
		background-color: var(--ion-color-primary-lighten-9);
		border: 1px solid var(--ion-color-primary-lighten-8);
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 90%;
		margin: 0 auto;
		padding: 90px 60px;
		text-align: center;

		p {
			font-size: 0.9em;
			font-weight: 700;
			margin-top: 15px;
		}
	}
}

.additional-help {
	margin: 10px auto 30px auto;
	max-width: 90%;
	text-align: center;

	span {
		border-bottom: 1px solid var(--ion-color-primary);
		cursor: pointer;
	}
}
</style>
