<template>
	<div>
		<video ref="videoPlayer" class="video-js" crossorigin="anonymous"></video>
	</div>
</template>

<script>
import videojs from 'video.js';

export default {
	name: 'VideoPlayer',
	props: {
		options: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			player: null,
		};
	},
	mounted() {
		this.player = videojs(this.$refs.videoPlayer, this.options, () => {
			this.$emit('ready', this.player);
		});
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	},
};
</script>
